.find-store-newsletter {
  .newsletter-inner {
    border-radius: 4px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot{
      margin-top: 0;
      &::before {
        border-bottom: none, // Remove the underline when not focused
      }
      &::after {
        border-bottom: none, // Remove the underline when focused
      }
    }
    .MuiInputLabel-formControl{
      transform: none;
      top: 10px;
      left: 10px;

      &.Mui-focused,
      &.MuiInputLabel-shrink {
        transform: translate(-5px, -10px) scale(0.75);
      }

    }
        .find-store-label, h2, h4 {
            font-size: 22px;
            font-weight: 700;
            color: inherit;
            margin-bottom: 0;
            margin-right: 40px;
            line-height: 1.2;
        }
        
        form {
          display: flex;
          position: relative;
             input ,
            .textfield-style {
                border: none;
                border-radius: 4px 0px 0px 4px;
                font-size: 15px;
                width: 252px;
                padding-left: 24px;
                float: left;
            }
            div  {
              z-index: 2;    
              div.items {
                position: static !important;
                height: auto;
                min-height: auto !important;
                padding: 2px 5px !important;
                line-height: 27px;
                color: #000;
              }
              @media(max-width: 767px) {
                input {
                  width: 100%;
                }
              }
              
              
              
            }
            
            ::-webkit-input-placeholder { /* Edge */
              color: #000;
            }

            :-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: #000;
            }

            ::placeholder {
              color: #000;
            }
            
            .btn {
                height: 36px;
                border: none;
                border-radius: 0px 4px 4px 0px;
                font-size: 15px;
                background: #fff;
                text-transform: uppercase;
                color:#E1251B;
                font-weight: 700;
                border-left: 2px solid #E1251B;
                padding-left: 24px;
                padding-right: 24px;
                line-height: 26px;
            }
        }

      

  }

  

  &.find-store-green {
    .newsletter-inner {
      background: #84bd00;
      form {
        .btn {
          border-color: #84bd00;
          color: #84bd00;
        }
      }
    }
  }
}

.find-store-newsletter.half-top-reverse {
  .newsletter-wrap {
    margin-top: 0;
    border: none;
    padding-top: 0;
    margin-bottom: 0px;
  }	
}


@media(max-width: 992px) {
.find-store-newsletter {
  .newsletter-inner {
    padding-right: 24px;
    padding-left: 24px;
    justify-content: space-between;
    h4 {		
      margin-right: 10px;		
    }

    form {
       input {
         width: 180px;
       }
      
      .btn {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
}

@media(min-width: 993px) {
.find-store-newsletter {
  .newsletter-inner {
    .find-store-label, h2, h4 {
      br {
        display: none;
      }
    }
  }
}

}


@media(max-width: 767px) {
  .find-store-newsletter {
    &.half-top-reverse {
      margin-top: -95px;
    }	
  .newsletter-inner {
    display:block;
    padding: 27px 24px;
    line-height: normal;
    h4 {
      text-align: center;
      margin-right:0;
      margin-bottom: 16px;
      font-size: 18px;
    }
    
    form {
      .textfield-style {
        margin: 0px 0px;
        float: left;
      }
      
      .btn {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
  }
}

